import React from "react";
import { graphql } from "gatsby";

import Layout from "../layout/Layout";

import Seo from "../components/Seo";
import InnerHero from "../components/InnerHero";
import Ventures from "../sections/ventures/Ventures";

const VenturesPage = ({
  data: {
    strapiNicoleWebsite: { venture: ventures },
  },
}) => {
  return (
    <main>
      <Seo title={"Nicole Tabs | Ventures"} description={""} language="en" />
      <InnerHero />
      <Ventures data={ventures} />
      <Layout innerPage></Layout>
    </main>
  );
};

export const query = graphql`
  query VenturesQuery {
    strapiNicoleWebsite {
      venture {
        cta
        id
        link
        name
        text
        videoId
        logo {
          alternativeText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  }
`;

export default VenturesPage;
