import React from "react";
import styled, { css } from "styled-components";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import respond from "../../styles/abstracts/mediaqueries";

import AlternativeButton from "../../components/AlternativeButton";
import YouTubeEmbed from "../../components/YouTubeEmbed";

const StyledVentures = styled.main`
  padding: 6rem 0;
  text-align: center;

  .venture {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 8rem;
    }
  }

  .logo {
    height: 5.5rem;

    ${respond(
      "phone-port",
      css`
        width: 80%;
        height: auto;
      `
    )}
  }

  .text {
    width: 60%;
    margin: 0 auto;

    ${respond(
      "tab-port",
      css`
        width: 75%;
      `
    )}
    ${respond(
      "phone-port",
      css`
        width: 90%;
      `
    )}
  }

  .yt-container {
    width: 70%;
    margin: 0 auto;

    ${respond(
      "tab-port",
      css`
        width: 80%;
      `
    )}
    ${respond(
      "phone-port",
      css`
        width: 100%;
      `
    )}
  }
  button {
    text-transform: uppercase;
  }
`;

const Ventures = ({ data }) => {
  return (
    <StyledVentures className="container">
      {data.map(({ cta, logo, text, videoId, id, link }, i) => {
        const isSvg = getImage(logo.localFile) === undefined;
        //console.log(isSvg);
        return (
          <article key={id} className="venture">
            {isSvg ? (
              <img className="logo" src={logo.localFile.publicURL} alt={logo.alternativeText} />
            ) : (
              <GatsbyImage
                className="logo"
                image={getImage(logo.localFile)}
                alt={logo.alternativeText}
                imgStyle={{ objectFit: "contain" }}
              />
            )}
            <p className="text">{text}</p>
            <div className="yt-container">
              <YouTubeEmbed id={videoId} />
            </div>

            <AlternativeButton>
              <a href={link}>{cta}</a>
            </AlternativeButton>
          </article>
        );
      })}
    </StyledVentures>
  );
};

export default Ventures;
